import React, { useRef, useEffect, useState, useMemo } from "react";
import {
    checkBrokerType,
    convertMinutesInHourAndMinutes,
    filterDataByHighestPriority,
    formatBrokerTypes,
    formatCurrency,
    rednderLegStatusIcon,
    rednderJetsatusIcon,
    afterAcceptedStatusShow,
    navigateMaverick,
    convertTo24HourFormat,
    isNumber,
    tripStatusIdIsAfterAccepted,
} from "helpers";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import priorityGreenBar from "assets/images/priority-green-bar.svg";
import priorityOrangeBar from "assets/images/priority-oranage-bar.svg";
import priorityRedBar from "assets/images/priority-red-bar.svg";
import planIconBlue from "assets/images/plan-icon-blue.svg";
import iconSchedulerBlue from "assets/images/icon-scheduler-blue.svg";
import parse from "html-react-parser";
import { useClientProfileStore } from "store/ClientProfileStore";
import { shallow } from "zustand/shallow";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { CustomSuggestiveSingleSelect } from "components/common/CustomSuggestiveSingleSelect";
import CustomTooltip from "components/common/CustomTooltip";
import { debounce } from "lodash";
import useAxiosPost from "hooks/useAxiosPost";
import { Urls } from "helpers/api-urls";
import { useMasterApiStore } from "store/MasterDataStore";
import propMatchup from "assets/images/prop-matchup.svg";
import propAlert from "assets/images/prop-alert.svg";
import propCancellation from "assets/images/prop-cancellation.svg";
import propActive from "assets/images/prop-active.svg";
import propInactive from "assets/images/prop-inactive.svg";
import propQueue from "assets/images/prop-queue.svg";
import { CustomMultiSelect } from "components/common/CustomMultiSelect";
import restart from "assets/images/restart.svg";
import pencilBlue from "assets/images/pencil-blue.svg";
import  SubStatus  from "pages/LegListing/SubStatus";
import LinkLeg from "components/common/LinkLeg";

const CharterTypeProfile = ({
    data,
    legId,
    clientInfoForMarginAndPriorityTripDataFromPhoenix,
    setRefresh,
    setIsRefreshLegListFromTripView,
    isRefreshLegListFromTripView,
    specialRequestsData,
    margin,
    openTripData,
}) => {
    const target = useRef(null);
    const [clientInfo, setClientInfo] =  React.useState([]);
    const [expanded, setExpanded] = React.useState(() => legId || "");
    const { openClientProfile, setOpenClientProfileView, setClientId, clientId, setOpenData } = useClientProfileStore(
        (state) => ({
            openClientProfile: state.openClientProfile,
            setOpenClientProfileView: state.setOpenClientProfileView,
            setClientId: state.setClientId,
            setOpenData: state.setOpenData,
            clientId: state.clientId,
        }),
        shallow
    );

    const { getAllSchedulerMasterData, getAllSchedulerSubStatusMasterData } = useMasterApiStore(
        (state) => ({
            getAllSchedulerMasterData: state.getAllSchedulerMasterData,
            getAllSchedulerSubStatusMasterData: state.getAllSchedulerSubStatusMasterData,
        }),
        shallow
    );
    const SP = ["International Fee", "Segment Fee", "Handling Fee", "Federal Excise Tax"];

    const [assignScheduler, setAssignScheduler] = useState([]);
    const [checkIfUsermadeAnyChanges, setCheckIfUsermadeAnyChanges] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [assignSchedulerSubStatus, setAssignSchedulerSubStatus] = useState([]);

    const { loadingPostData: assignSchedulerLoading, sendRequest: sendRequestToAssignScheduler, data: assignSchedulerApiResponse } = useAxiosPost();
    const { loadingPostData: assignSubStatusLoading, sendRequest: sendRequestToAssignSchedulerSubStatus, data: assignSchedulerSubStatusApiResponse } = useAxiosPost();
    
    useEffect(() => {
        setClientInfo(clientInfoForMarginAndPriorityTripDataFromPhoenix);
    }, [clientInfoForMarginAndPriorityTripDataFromPhoenix]);

    const handleAssignScheduler = debounce(async (name, value, tripId, blank = false) => {
        if (value || blank) {
            const payload = {
                schedulerList: [
                    {
                        schedulerId: value?.id,
                        id: tripId,
                        schedulerName: value?.name,
                    },
                ],
            };
            setAssignScheduler({ [name]: value });
            sendRequestToAssignScheduler("post", `${Urls.AssignSchedulerToLeg}`, {
                ...payload,
            });
            setRefresh((prev) => prev + 1);
            setIsRefreshLegListFromTripView(isRefreshLegListFromTripView);
        }
    }, 300);
    const handleAssignSchedulerSubStatus = async (name, value, legId, e, dataItem) => {
        const payload = {
            legId: legId,
            schedulerSubStatus: value?.flatMap((v) => v?.id),
        };
        if (value.length) {
            let operation = e.operation;
            if (operation == "delete") {
                let id = e.items[0]["id"];
                payload["removeSubStatus"] = id;
                setIsRemove(true);
            }
            if (operation == "toggle") {
                if (dataItem) {
                    let id = e.items[0]["id"];
                    let phoLegsSchedulerSubStatus = dataItem.split(",");
                    if (phoLegsSchedulerSubStatus.length > value.length) {
                        payload["removeSubStatus"] = id;
                        setIsRemove(true);
                    }
                }
            }
        }
        if (payload.schedulerSubStatus.length == 0) {
            setIsRemove(true);
        }
        setAssignSchedulerSubStatus({ [name]: value });
        await sendRequestToAssignSchedulerSubStatus("post", `${Urls.AssignSchedulerSubStatus}`, {
            ...payload,
        });
        setRefresh((prev) => prev + 1);
        setIsRefreshLegListFromTripView(isRefreshLegListFromTripView);
    };
    const { tripId,restarted, jetCard, aircraftTypes, brokers, legs, verbalQuote, tripNotes, notes, status, client, tripType ,  id , tripLegOption } = data?.responseData || {};
    const getAllSchedulerSubStatusMasterDataRemovedNew = getAllSchedulerSubStatusMasterData?.data?.responseData?.filter((f) => f?.id !== 7);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const delay = 2000;

        setTimeout(() => {
            setIsLoading(false);
        }, delay);
    }, []);
    const [showRowSubStatus, setShowRowSubStatus] = React.useState(-1);

    const sortedTripNotes = useMemo(() => {
        if(tripNotes && tripNotes.length > 1){
            return [...tripNotes].sort((a, b) => a.id - b.id)
        } else {
            return tripNotes
        }
    }, [tripNotes])

    const editTrip = () => {
        if (tripStatusIdIsAfterAccepted.includes(status?.id)) {                                                               
            navigateMaverick(`trip/${id}/edit`);
        }
    }
    const RenderLabelValue = ({label, value}) => {
        const isAccepted = tripStatusIdIsAfterAccepted.includes(status?.id);
        return (
            <div className="col-sm-3 d-flex flex-column">
                <label className="role-view-label f-lh-11 mb-1">{label}</label>
                <div className="fs-14 d-inline flex-column f-lh-13" style={{ width: value !== null ? "100%" : "50px" }}>
                    <CustomTooltip description={isAccepted ? null : "Trip needs to be accepted before assignment"} placement={"top"}>
                        <span className="font-600 text-blue-darken" onClick={editTrip} role={isAccepted ? "button" : undefined}>
                            {value !== null ? value : "Assign"}
                        </span>
                    </CustomTooltip>
                </div>
            </div>
        );
    };

    const [showRowLinkLeg, setShowRowLinkLeg] = React.useState(-1);

    const setLinkLegCount = async (getLinkLegsListResponse, legId) => {
        let clientInfoNew = clientInfo.map((leg)=>{
            if (legId == leg.legId) {
                leg.linkedLegsCount = getLinkLegsListResponse?.totalRecords;
                leg.legLinkProjection = getLinkLegsListResponse?.responseData;
            }
            return leg
        })
        setClientInfo(clientInfoNew)
    };
    return (
        <div>
            <p
                onClick={() => {
                    setOpenClientProfileView(true);
                    setOpenData(client);
                    setClientId(client?.id);
                }}
                className="font-500 text-dark-primary mb-1 pointer-class d-inline"
            >
                {data?.responseData?.client?.name}
            </p>
            <div className="d-flex align-items-start justify-content-between">
                <div className="d-flex .mx-w-500px"> 
                    <h1 className="font-300 d-flex text-primary mb-1 flex-column-on-small align-items-center"
                    role="button"
                        onClick={() => {
                            navigateMaverick(`trip/${id}`)
                        
                        }}>
                        {tripId}
                        {tripStatusIdIsAfterAccepted.includes(status?.id) && (
                            <img
                                src={pencilBlue}
                                role="button"
                                alt="Pencil Icon"
                                width="35"
                                height="35"
                                style={{ marginLeft: "10px", paddingBottom: "9px" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigateMaverick(`trip/${id}/edit`)
                                }}/>)}
                        {restarted && (
                            <span className="d-inline-block ms-1">
                                <img src={restart} width={24} className="relative-icon-minus-2" />
                            </span>)}
                    </h1>
                    <div>
                    <div className="d-flex flex-wrap">
                        {aircraftTypes?.map((aircraft) => (
                            <div className="ms-1 mb-1 bubble-style  blue-bubble">{aircraft.displayName[0]}</div>
                        ))}
                    </div>
                    </div>
                </div>
                <div className="status-title-box light-blue">
                    {rednderJetsatusIcon(status?.displayName)}
                    {status?.displayName}
                </div>
            </div>
            <div className="divider-hr mt-10 mb-20"></div>

            <div className="row mb-25">
                <div className="col-sm-6">
                    <p className="role-view-label mb-1">Verbal Quote</p>
                    <div className="d-flex flex-column text-dark-grey">
                        <span className="font-500 f-lh-18">{verbalQuote ? formatCurrency(verbalQuote) : "-"}</span>
                    </div>
                </div>
                {tripStatusIdIsAfterAccepted.includes(status?.id) ? (
                    <>
                        <div className="col-sm-6">
                            <p className="role-view-label mb-1">Trip Contract Amount (Pretax)</p>
                            <div className="d-flex flex-column text-dark-grey">
                                <span className="labelValue f-lh-18 pe-2 w-100">{isNumber(tripLegOption?.revenue) ? formatCurrency(tripLegOption?.revenue) : "-"}</span>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="col-sm-6">
                            <p className="role-view-label mb-1">Assigned broker(s)</p>
                            <div className="d-flex align-items-center text-dark-grey">
                                <span className="f-lh-18 pe-2 w-100">
                                    {checkBrokerType(clientInfo?.[0]?.brokerName)?.length
                                        ? formatBrokerTypes(checkBrokerType(clientInfo?.[0]?.brokerName))
                                        : "-"}
                                    {/* {brokers
                                ?.filter((broker) => !broker?.isHandler)
                                ?.map((handler) => handler?.name)
                                ?.join(", ") || "-"} */}
                                </span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="row mb-25">
                {tripStatusIdIsAfterAccepted.includes(status?.id) && (
                    <>
                        <div className="col-sm-6">
                            <p className="role-view-label mb-1">Assigned broker(s)</p>
                            <div className="d-flex align-items-center text-dark-grey">
                                <span className="f-lh-18 pe-2 w-100">
                                    {checkBrokerType(clientInfoForMarginAndPriorityTripDataFromPhoenix?.[0]?.brokerName)?.length
                                        ? formatBrokerTypes(checkBrokerType(clientInfoForMarginAndPriorityTripDataFromPhoenix?.[0]?.brokerName))
                                        : "-"}
                                </span>
                            </div>
                        </div>
                    </>
                )}
                <div className={tripStatusIdIsAfterAccepted.includes(status?.id) ? "col-sm-6" : "col-sm-12"}>
                    <p className="role-view-label mb-1">Assigned Handler(s)</p>
                    <div className="d-flex flex-column text-dark-grey">
                        <span className="font-500 f-lh-18">
                            {brokers
                                ?.filter((broker) => broker?.isHandler)
                                ?.map((handler) => handler?.name)
                                ?.join(", ") || "-"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="row mb-20">
                <div className="col-sm-12 d-flex flex-column">
                    <label className="role-view-label f-lh-11 mb-1">Notes</label>
                    <span className="fs-14 f-lh-13 text-dark-blue">
                        {sortedTripNotes?.map((note, i) => (
                            <>
                                <span className="text-break-word">
                                    Note {i + 1} : {note?.notes}
                                </span>
                                <br />
                            </>
                        )) || "-"}
                    </span>
                </div>
            </div>
            <div className="divider-hr mt-10 mb-20"></div>
            <PanelBar className="client-profile-accordian trip">
                {legs?.map((item, i) => {
                    const findAdditionalDetailsFromPhoenix = clientInfo?.find((leg) => leg?.dataSourceId == item?.id);
                    return (
                        <>
                            <PanelBarItem
                                className={`client-profile-accordian-item ${legId == item.id ? `highlight-blue` : ""}`}
                                key={item?.id}
                                id={item?.id}
                                expanded={expanded == item?.id}
                                title={
                                    <>
                                        {expanded != item?.id ? (
                                            <td>
                                                <div className="fs-16 font-500 text-dark-grey">
                                                    <span>Leg {i + 1} | </span>
                                                    <span>
                                                        {item?.originAirport?.code} - {item?.destinationAirport?.code}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span className="font-600">
                                                        <img src={iconSchedulerBlue} alt="Schedular Icon" /> {item?.scheduler?.name}
                                                    </span>{" "}
                                                    <span className="mx-2"> |</span>
                                                    <span className="fs-14 font-500 text-dark-grey">
                                                        <img src={planIconBlue} alt="Plan Icon" className="me-1 align-baseline" /> {item?.displayDepartDate} at {convertTo24HourFormat(item?.displayDepartTime)}
                                                    </span>
                                                </div>
                                            </td>
                                        ) : (
                                            <td></td>
                                        )}
                                    </>
                                }
                                onSelect={(e) => {
                                    if (e.id == expanded) {
                                        setExpanded(e.id ? null : e.id);
                                    } else {
                                        setExpanded(e.id);
                                    }
                                }}
                            >
                                {expanded && legs?.length && !isLoading && (
                                    <div>
                                        <div className="d-flex justify-content-between pr-20">
                                            <p className="font-500 mb-1 text-dark-grey">Leg {i + 1}</p>
                                            <p className="fs-14 mb-1 text-blue-darken">
                                                <img src={iconSchedulerBlue} alt="Schedular Icon" />{" "}
                                                <div className="font-600 d-inline-flex">
                                                    <OverlayTrigger
                                                        rootClose={true}
                                                        trigger="click"
                                                        placement="top"
                                                        container={this}
                                                        overlay={
                                                            <Popover id="popover-basic" container={target} className="custom-popover">
                                                                <Popover.Header as="h3"></Popover.Header>
                                                                <Popover.Body>
                                                                    {item?.scheduler?.name?.trim() && (
                                                                        <FaTimes
                                                                            className="position-absolute swc-ms-cross-button"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                document.body.click();
                                                                                handleAssignScheduler("schedulerList", "", findAdditionalDetailsFromPhoenix?.legId, true);
                                                                            }}
                                                                        />
                                                                    )}
                                                                    <CustomSuggestiveSingleSelect
                                                                        dataList={[{ id: "", name: "Select" }].concat(getAllSchedulerMasterData?.data?.responseData || [])}
                                                                        removeCrossIcon
                                                                        handleFilterName={(e) => {
                                                                            if (
                                                                                e.nativeEvent.code == "ArrowDown" ||
                                                                                (e.target.value && !e.target.value.id) ||
                                                                                e.nativeEvent.code == "ArrowUp" ||
                                                                                !e.target.value
                                                                            ) {
                                                                                document.getElementById("b_" + i).style.display = "block";
                                                                                document.getElementById("d_" + i).style.display = "none";
                                                                                document.getElementById("b_1" + i).style.display = "block";
                                                                                return;
                                                                            } else {
                                                                                document.body.click();
                                                                                document.getElementById("b_" + i).style.display = "none";
                                                                                document.getElementById("d_" + i).style.display = "block";
                                                                                handleAssignScheduler("schedulerList", e.target.value, findAdditionalDetailsFromPhoenix?.legId);
                                                                            }
                                                                        }}
                                                                        keyName="scheduler"
                                                                        name="schedulerList"
                                                                        dataItemKey="id"
                                                                        dataIndex={i}
                                                                        textField={"name"}
                                                                        classN={"scroll_id"}
                                                                        onCloseEnv={(e) => {
                                                                            document.getElementById("b_" + i).style.display = "block";
                                                                            document.getElementById("d_" + i).style.display = "none";
                                                                        }}
                                                                        label={
                                                                            item?.scheduler?.name?.trim() ? (
                                                                                <>
                                                                                    <p className="fs-12 mb-0 text-light-grey">Scheduler Assigned</p>
                                                                                    <p className="fs-16 mb-0 text-dark-grey">
                                                                                        <span id={"b_" + i}></span>
                                                                                        <span id={"d_" + i}>{item?.scheduler?.name}</span>
                                                                                        <span id={"b_1" + i}></span>
                                                                                    </p>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <p className="fs-14 mb-0 text-light-grey ">Scheduler Name</p>
                                                                                    <p className="fs-16 mb-0 text-dark-grey">
                                                                                        <span id={"b_1" + i} style={{ display: "none" }}></span>
                                                                                        <span id={"b_" + i}></span>
                                                                                        <span id={"d_" + i}></span>
                                                                                    </p>
                                                                                </>
                                                                            )
                                                                        }
                                                                    />
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                    >
                                                        {item?.scheduler?.name?.trim() ? (
                                                            <span className="d-inline-block pointer-class">
                                                                <CustomTooltip description={item?.scheduler?.name} className="link-col  custom-wrapped-truncated table-truncated">
                                                                    {item?.scheduler?.name}
                                                                </CustomTooltip>
                                                            </span>
                                                        ) : (
                                                            <span className="mb-0 pointer-class">{"Assign"}</span>
                                                        )}
                                                    </OverlayTrigger>
                                                </div>
                                                <span className="mx-2"> |</span> <span class="dark-blue-elips elips-status-table me-1"></span>{" "}
                                                <span className="fs-14 font-500 text-dark-grey">
                                                    <img src={planIconBlue} alt="Plan Icon" className="me-1 align-baseline" /> {item?.displayDepartDate} at {convertTo24HourFormat(item?.displayDepartTime)}
                                                </span>
                                            </p>
                                        </div>

                                        <div className="divider-hr trip-collapse-hr mt-20 mb-20"></div>
                                        <div className="main-client-trip-table custom-scrolbar">
                                            <div className="row mb-20">
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Route </label>
                                                    <span className="fs-14 f-lh-13 font-600 text-dark-blue">
                                                        {item?.originAirport?.code}
                                                        <span className="onewayTripIcon" />
                                                        {item?.destinationAirport?.code}
                                                    </span>
                                                </div>
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Travel Date</label>
                                                    <span className="fs-14 f-lh-13 text-dark-blue">{item?.displayDepartDate}</span>
                                                </div>
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Travel Time </label>
                                                    <span className="fs-14 f-lh-13 text-dark-blue">{convertTo24HourFormat(item?.displayDepartTime)}</span>
                                                </div>
                                                <div className="col-sm-3 ps-0 d-flex flex-column">
                                                    <div className="row">
                                                        <div className="col-sm-6 d-flex flex-column">
                                                            <label className="role-view-label f-lh-11 mb-1">ETE </label>
                                                            <span className="fs-14 f-lh-13 text-dark-blue">{convertMinutesInHourAndMinutes(item?.durationInHrMin)}</span>
                                                        </div>
                                                        <div className="col-sm-6 d-flex flex-column text-center">
                                                            <label className="role-view-label f-lh-11 mb-1">PAX </label>
                                                            <span className="fs-14 f-lh-13 text-dark-blue">{item?.numberOfPassengers}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-20">
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Margin </label>
                                                    <span className="fs-14 f-lh-13 text-dark-blue">{!afterAcceptedStatusShow(tripType?.id, status?.displayName) && <>{margin ?? 0}%</>}</span>
                                                </div>
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Broker</label>
                                                    <span className="fs-14 f-lh-13 text-dark-blue">
                                                        {checkBrokerType(findAdditionalDetailsFromPhoenix?.brokerName)?.length
                                                            ? formatBrokerTypes(checkBrokerType(findAdditionalDetailsFromPhoenix?.brokerName))
                                                            : "-"}
                                                        {/* {brokers
                                                        ?.filter((broker) => !broker?.isHandler)
                                                        ?.map((handler) => handler?.name)
                                                        ?.join(", ") || "-"} */}
                                                    </span>
                                                </div>
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Priority</label>
                                                    <span className="fs-14 f-lh-13 text-dark-blue d-flex align-items-baseline">
                                                        {""}
                                                        <img
                                                            className="me-1 align-text-top"
                                                            src={
                                                                findAdditionalDetailsFromPhoenix?.priority == "1"
                                                                    ? priorityGreenBar
                                                                    : findAdditionalDetailsFromPhoenix?.priority == "2"
                                                                    ? priorityOrangeBar
                                                                    : findAdditionalDetailsFromPhoenix?.priority == "3"
                                                                    ? priorityRedBar
                                                                    : priorityOrangeBar
                                                            }
                                                            alt="Green bar"
                                                        />
                                                        {findAdditionalDetailsFromPhoenix?.priority == "1"
                                                            ? "Low"
                                                            : findAdditionalDetailsFromPhoenix?.priority == "2"
                                                            ? "Medium"
                                                            : findAdditionalDetailsFromPhoenix?.priority == "3"
                                                            ? "High"
                                                            : "Medium"}
                                                    </span>
                                                </div>
                                                <div className="col-sm-3 ps-0 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Status</label>
                                                    <span className="fs-14 f-lh-13 d-inline-flex text-dark-blue">
                                                        <SubStatus
                                                            data={{
                                                                ...findAdditionalDetailsFromPhoenix,
                                                                phoLegsSchedulerSubStatus: findAdditionalDetailsFromPhoenix?.phoLegsSchedulerStatus,
                                                                schedulerStatus: findAdditionalDetailsFromPhoenix?.status,
                                                            }}
                                                            index={i}
                                                            showRowSubStatus={showRowSubStatus}
                                                            setShowRowSubStatus={setShowRowSubStatus}
                                                            handleAssignSchedulerSubStatus={handleAssignSchedulerSubStatus}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="row mb-20">
                                                <RenderLabelValue label="Assigned Operator" value={item?.aircraft?.operator?.name ? item.aircraft.operator.name : null} />
                                                <RenderLabelValue
                                                    label="Assigned Aircraft/Tail No"
                                                    value={
                                                        item?.aircraft?.name || item?.aircraft?.tailNumber
                                                            ? `${item.aircraft.name} ${item?.aircraft?.tailNumber ? `/ ${item.aircraft.tailNumber}` : ""}`
                                                            : null
                                                    }
                                                />
                                                <RenderLabelValue label="Operator Cost" value={item?.operatorCost !== null ? formatCurrency(item.operatorCost) : null} />
                                                <div className="col-sm-3 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Linked Legs</label>
                                                    <div className="fs-14 d-flex flex-column f-lh-13 text-dark-blue">
                                                        <LinkLeg
                                                            key={i + "outerLink1"}
                                                            data={findAdditionalDetailsFromPhoenix}
                                                            index={i}
                                                            showRowLinkLeg={showRowLinkLeg}
                                                            setShowRowLinkLeg={setShowRowLinkLeg}
                                                            setLinkLegCount={setLinkLegCount}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-12 d-flex flex-column">
                                                    <label className="role-view-label f-lh-11 mb-1">Special Requests</label>
                                                    <div className="fs-14 d-flex flex-column f-lh-13 text-dark-blue">
                                                        <span className="f-lh-16">
                                                            {specialRequestsData?.responseData
                                                                ?.filter((request) => {
                                                                    return request?.legIndex.includes(i) && !SP.includes(request?.name);
                                                                })
                                                                ?.map((request, i) => (
                                                                    <>
                                                                        <span className="text-word-break">
                                                                            Special Request {i + 1} : {request?.name} {request?.shortDescription ? "-" : ""} {request?.shortDescription}
                                                                        </span>
                                                                        <br />
                                                                    </>
                                                                ))}
                                                        </span>
                                                    </div>
                                                </div>
                                             
                                            </div>
                                          
                                        </div>
                                    </div>
                                )}
                            </PanelBarItem>
                        </>
                    );
                })}
            </PanelBar>
        </div>
    );
};

export default CharterTypeProfile;
